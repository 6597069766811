<template >
  <div>
    <topnav></topnav>
    <div class="page-wrap">
      <div class="big-top-img-head how">
        <h1 v-if="$lang === 'en'" class="txt160 serif big-top-head loading">
          {{ $local.howPage.title }}
        </h1>
        <h1 v-if="$lang === 'fr'" class="txt160 serif big-top-head loading">
          {{ $local.howPage.titleFrench || $local.howPage.title }}
        </h1>
        <p v-if="$lang === 'en'" class="top-subtitle">
          {{ $local.howPage.subtitle }}

        </p>
        <p v-if="$lang === 'fr'" class="top-subtitle">
          {{ $local.howPage.subtitleFrench || $local.howPage.subtitle }}

        </p>


        <img src="../assets/gem-bg.svg" class="big-top-img" />
      </div>
      <div class="how-sec">
        <div v-for="point in  $local.howPage.points" :key="point._id" class="how-box">
          <div class="how-box-head"><img :src="point.icon.imageURL" />
            <p v-if="$lang === 'en'" class="how-box-title serif">
              {{ point.title }}
            </p>
            <p v-if="$lang === 'fr'" class="how-box-title serif">
              {{ point.titleFrench || point.title }}
            </p>
          </div>
          <div v-if="$lang === 'en'">
            <div v-html="point.body"></div>
          </div>
          <div v-if="$lang === 'fr'">
            <div v-html="point.bodyFrench || point.body"></div>
          </div>
        </div>

      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>


<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
import { howPage } from '@/data/localData.json'

export default {
  components: {
    Topnav,
    Botfooter,
  },
  computed: {
    $local() {
      return { howPage }
    }
  }
};
</script>
